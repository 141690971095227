export default {
  data() {
    return {
      formValue: {
        countInfo: "",
        generateStoreId: "",
        scheduleSettingInfo: {
          firstCollectionDate: "",
          collectionTime: "",
          isRecurring: false,
          registMemberId: "",
        },
        isUseAcquiredManifestNo: null,
        manifestInfo: {
          manifestStatusId: "",
          registMember: {
            id: null,
            name: null,
          },
          generateMember: {
            id: null,
            name: null,
          },
          routeId: "",
          conversionFactorKg: null,
          conversionFactorM3: null,
          conversionFactorLiter: null,
          conversionFactorUnitNumber: null,
        },
        wasteInfo: {
          jwnetItemTypeId: "",
          wasteName: "",
          wasteQuantity: "",
          wasteUnitTypeId: "",
          quantityConfirmBusinessTypeId: "",
          shapeTypeId: "",
          shapeQuantity: "",
          disposalWayTypeId: "",
        },
        harmfulInfo: [
          {
            harmfulTypeId: 0
          }
        ],
        remarksInfo: {
          remarksList: [
            {
              remarks: "",
            },
          ],
        },
        contactNoInfo: {
          contactNoList: [
            {
              contactNo: "",
            }
          ],
        },
        recurringInfo: {
          recurringTypeId: "",
          intervalValue: "",
          weekOfDayTypeList: [],
          recurringEndDate: null,
        },
        labelInfo: {
          labels: [],
        },
        anyGenerateStoreInfo: {
          anyGenerateStoreName: "",
          anyGenerateStoreAddress: {
            zipCode: "",
            prefecturesId: "",
            address: "",
            buildingName: "",
          },
        },
      },
    };
  },
  methods: {},
};
