export default {
  methods: {
    // 連絡番号追加
    addContactNoHandler() {
      if(this.formValue.contactNoInfo.contactNoList.length >= 3) return
      this.formValue.contactNoInfo.contactNoList.push({
        contactNo: "",
      });

      //バリデーションも追加
      this.validate.contactNo = [...this.validate.contactNo, { isValid: true, errMsg: "" }]
    },
    // 連絡番号削除
    removeContactNoHandler(contactNo, index) {
      this.formValue.contactNoInfo.contactNoList = this.formValue.contactNoInfo.contactNoList.filter(
        (cn) => cn != contactNo
      );

      //バリデーションも削除
      this.validate.contactNo = this.validate.contactNo.filter((_cv, i) => i != index)
    },
  },
};
