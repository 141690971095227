<template>
  <div v-if="isShow" class="c-modal md">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">住所選択</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label for="">住所</label>
          </div>
          <div class="c-inputWrap__items">
            <Selectbox name="start-hour" :value.sync="selectedAddressIndex">
              <option
                v-for="(address, index) in addresses"
                :key="'address-select-' + index"
                :value="index"
              >
                {{ getAddressStr(address) }}
              </option>
            </Selectbox>
          </div>
        </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="clickSetHandler">決定</div>
      </div>
    </div>
  </div>
</template>

<script>
import Selectbox from "@/components/parts/input/Selectbox";

export default {
  components: {
    Selectbox,
  },
  props: {
    isShow: {
      type: Boolean,
    },
    addresses: {
      type: Array,
    },
  },
  data() {
    return {
      selectedAddressIndex: null,
    };
  },
  methods: {
    getAddressStr(obj) {
      return `${obj.address1} ${obj.address2} ${obj.address3}`;
    },
    closeModalHandler() {
      this.$emit("update:isShow", false);
    },
    clickSetHandler() {
      this.$emit("update:isShow", false);
      this.$emit("selectAddress", this.addresses[this.selectedAddressIndex]);
      this.selectedAddressIndex = null;
    },
  },
};
</script>
