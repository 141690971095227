export default {
  data() {
    return {
      // routeInfo  from callApi.js
      routeCompanyName: {},
      routeTempVal: "",
      collectCompanyId: "",
      disposalCompanyId: "",
      routeOptions: [],
      wdsFilePath: "",
      jwnetItemTypeName: "",

      // 一時的に使用用
      // 絞り込み後のルート（画面遷移時はAPI返り値全件を代入）
      narrowedRoutesList: [],
    };
  },
  methods: {
    // 廃棄物種類のchange処理
    jwnetItemChangeHandler() {
      this.setSelectedJwnetItem();
      const selectedjwnetItemId = this.formValue.wasteInfo.jwnetItemTypeId;
      if (selectedjwnetItemId == 0) {
        this.narrowedRoutesList = this.routesInfo;
      } else {
        const newRouteList = this.routesInfo.filter(
          (routeInfo) => routeInfo.jwnetItemType.id == selectedjwnetItemId
        );
        this.narrowedRoutesList = newRouteList;
      }
      if (this.formValue.manifestInfo.routeId) {
        const confirmRoute = this.narrowedRoutesList.filter(
          (r) => r.routeId === this.formValue.manifestInfo.routeId
        );
        if (confirmRoute.length == 0 || selectedjwnetItemId == 0) {
          this.formValue.manifestInfo.routeId = null;
          this.selectedRoute = null;
        } else {
          this.onChangeRouteHandler();
        }
      } else {
        this.formValue.manifestInfo.routeId = null;
        this.selectedRoute = null;
      }
    },

    // 選択された廃棄物種類をdataの値にセット
    setSelectedJwnetItem() {
      this.selectedJwnetItem = this.jwnetItemsOpt.filter(
        (ji) => ji.id === this.formValue.wasteInfo.jwnetItemTypeId
      )[0];
      if (!this.selectedJwnetItem.isHarmful) {
        this.formValue.harmfulInfo = [{ harmfulTypeId: null }];
      }
    },

    /**
     * ルートの選択後に各会社情報を一時データに代入
     */
    getRouteCompanyName() {
      this.routesInfo.map((routeInfo) => {
        if (routeInfo.routeId == this.formValue.manifestInfo.routeId) {
          this.routeCompanyName.generateStore = routeInfo.generateStore.name;
          this.jwnetItemTypeName = routeInfo.jwnetItemType.name;
          this.routeCompanyName.disposalCompany = routeInfo.disposalCompany.name;
          routeInfo.collectCompanys.map((collectCompany) => {
            if (collectCompany.id == this.collectCompanyId) {
              this.routeCompanyName.collectCompany = collectCompany.name;
            }
          });
        }
      });
    },

    // 送信時はheaderを削除
    setAddedLabelData() {
      const formattedLabelData = this.routeLabels.map((routeLabel) => {
        return {
          id: routeLabel.labelId,
          header: routeLabel.labelHeader,
          labelValue: routeLabel.labelDefaultValue,
          labelUnit: routeLabel.labelUnit ? routeLabel.labelUnit : "",
        };
      });
      this.formValue.labelInfo.labels = [...formattedLabelData];
    },

    // ルート情報APIで取得した値を適用
    setRouteInfoValToFormVal() {
      const f = this.formValue;
      const r = this.routeDetailInfo;
      const v = this.validate;

      f.wasteInfo.jwnetItemTypeId = r.jwnetItemTypeInfo.id; // 廃棄物種類
      f.wasteInfo.wasteNameId = r.wasteNameInfo.id; // 廃棄物名称Id
      f.wasteInfo.detailWasteName = r.detailWasteName;
      f.wasteInfo.wasteQuantity = r.plansQuantity; // 廃棄物数量
      if (r.plansWasteUnitType) {
        f.wasteInfo.wasteUnitTypeId = r.plansWasteUnitType.id; // 廃棄物単価
      }
      if (r.quantityConfirmBusinessType) {
        f.wasteInfo.quantityConfirmBusinessTypeId =
          r.quantityConfirmBusinessType.id; // 数量確定者
      }
      if (r.shapeType) {
        f.wasteInfo.shapeTypeId = r.shapeType.id; // 荷姿
      }
      f.wasteInfo.shapeQuantity = r.shapeQuantity; // 荷姿数量

      if (r.disposalWayType) {
        f.wasteInfo.disposalWayTypeId = r.disposalWayType.id; // 処分方法
      }
      // 有害物質リスト
      if (r.harmfulInfo.length) {
        r.harmfulInfo.forEach((elem) => {
          f.harmfulInfo.push({ harmfulTypeId: elem.id });
        });
      }

      // 連絡番号
      if (r.contactNoInfo.length > 0) {
        r.contactNoInfo.forEach((contactNo) => {
          f.contactNoInfo.contactNoList.push(contactNo);
          v.contactNo.push({ isValid: true, errMsg: "" });
        });
      }

      // 備考
      if (r.remarksInfo.length > 0) {
        r.remarksInfo.forEach((remark) => {
          f.remarksInfo.remarksList.push(remark);
          v.remarks.push({ isValid: true, errMsg: "" });
        });
      }

      this.routeLabels = r.labelInfo; // ラベル

      // ルート荷姿選択肢取得APIを呼び出す
      this.getShapeApi(r.routeId);

      // ルート処分方法選択肢取得APIを呼び出す
      this.getDisposalWayTypesApi(r.routeId);

      // CBA品目IDを元にCBA品目別廃棄物名称選択肢取得APIを呼び出す
      this.getWasteNameCBAItemApi(r.cbaItemInfo.id);

      // 換算係数の設定
      f.manifestInfo.conversionFactorKg = r.conversionFactorKg
      f.manifestInfo.conversionFactorM3 = r.conversionFactorM3
      f.manifestInfo.conversionFactorLiter = r.conversionFactorLiter
      f.manifestInfo.conversionFactorUnitNumber = r.conversionFactorUnitNumber
    },

    // ルート情報から設定する項目をリセット
    resetRouteInfoFromFormValue (f) {
      f.wasteInfo.jwnetItemTypeId = null;
      f.wasteInfo.wasteNameId = null;
      f.wasteInfo.detailWasteName = null;
      f.wasteInfo.wasteQuantity = null;
      f.wasteInfo.wasteUnitTypeId = null;
      f.wasteInfo.quantityConfirmBusinessTypeId = null;
      f.wasteInfo.shapeTypeId = null;
      f.wasteInfo.shapeQuantity = null;
      f.wasteInfo.disposalWayTypeId = null;
      f.harmfulInfo = [];
      f.contactNoInfo.contactNoList = [];
      f.remarksInfo.remarksList = [];
      f.manifestInfo.conversionFactorKg = null;
      f.manifestInfo.conversionFactorM3 = null;
      f.manifestInfo.conversionFactorLiter = null;
      f.manifestInfo.conversionFactorUnitNumber = null;

      // 排出事業場情報が変更できるルートに対して任意事業場情報もリセット
      f.anyGenerateStoreInfo = {
        anyGenerateStoreName: "",
        anyGenerateStoreAddress: {
          zipCode: "",
          prefecturesId: "",
          address: "",
          buildingName: "",
        },
      };
      this.selectedPrefectureTemp = null;
    },

    // ルートとルート情報の初期化
    resetRouteAndCompanyInfo() {
      // 現状で選択したルート情報で値を設定する
      this.formValue.manifestInfo.routeId = ""
      this.routeCompanyName = {}
    },

    // ルート情報から設定する項目のバリデーションをリセット
    resetValidateRouteInfo(v) {
      v.collectionDate = { isValid: true, errMsg: "" },
      v.wasteQuantity = { isValid: true, errMsg: "" };
      v.detailWasteName = { isValid: true, errMsg: "" };
      v.wasteUnitTypeId = { isValid: true, errMsg: "" };
      v.zipCode = { isValid: true, errMsg: "" };
      v.prefecture = { isValid: true, errMsg: "" };
      v.address = { isValid: true, errMsg: "" };
      v.contactNo = [];
      v.remarks = [];
      v.conversionFactorKg = { isValid: true, errMsg: "" };
      v.conversionFactorM3 = { isValid: true, errMsg: "" };
      v.conversionFactorLiter = { isValid: true, errMsg: "" };
      v.conversionFactorUnitNumber = { isValid: true, errMsg: "" };
      v.anyGenerateStoreName = { isValid: true, errMsg: "" };
      v.zipCode = { isValid: true, errMsg: "" };
      v.prefecture = { isValid: true, errMsg: "" };
      v.address = { isValid: true, errMsg: "" };
      v.buildingName = { isValid: true, errMsg: "" };
    },

    // ルート選択時
    onChangeRouteHandler() {
      // 画面先頭のエラーメッセージを消す
      this.errMsgs = [];
      if (this.formValue.manifestInfo.routeId) {
        const route = this.narrowedRoutesList.filter(
          (r) => r.routeId === this.formValue.manifestInfo.routeId
        )[0];
        if (route) {
          this.routeCompanyName.generateStore = route.generateStore.name;
          this.routeCompanyName.collectCompany = route.collectCompanys
            .map((cc) => cc.name)
            .join(",");
          this.routeCompanyName.disposalSite = route.disposalSite.name;
          this.jwnetItemTypeName = route.jwnetItemType.name
          this.getRouteInfoApi(this.formValue.manifestInfo.routeId).then(() => {
            // もともとルートで設定した情報を初期化する
            this.resetRouteInfoFromFormValue(this.formValue);
            // バリデーションチェックリセット
            this.resetValidateRouteInfo(this.validate);
            // 現状で選択したルート情報で値を設定する
            this.setRouteInfoValToFormVal();
            this.setAddedLabelData();
          });
        }
        this.getRoutePriorconsultationAlert()
      }
    },
  },
};
