export default {
  methods: {
    // 有害物質名追加
    addHarmfulTypeHandler() {
      this.formValue.harmfulInfo.push({
        harmfulTypeId: null,
      });
    },
    // 有害物質名削除
    removeHarmfulTypeHandler(harmfulType) {
      this.formValue.harmfulInfo = this.formValue.harmfulInfo.filter(
        (ht) => ht != harmfulType
      );
    },
  },
};
